.conteudo-contas{
    width: 100%;
    height: 100%;

    .container-search{
        margin-bottom: .5%;

        svg{
            &:last-of-type{
                margin-left: 0;
                cursor: pointer;
            }
        }
    }

    .container-filters{
        // display: flex;
        align-items: flex-start;
        justify-content: space-between;
        max-height: 0;
        display: flex;
        overflow: hidden;
        visibility: hidden;
        transition: all .5s cubic-bezier(.25,.8,.25,1);

        // .content{
        //     width: 90%;
        //     margin: 0 auto;
        //     font-size: .9em;
        //     opacity: .8;
        // }

        .content-container-filters{
            background-color: #fff;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            border-radius: 5px;
            width: calc(100% / 3.1);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: .8em;

            h6{
                width: 100%;
                text-align: center;
                font-size: .9em;
                margin-bottom: .7em;
                font-weight: 400;
            }

            >form{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;

                >div{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-bottom: .5em;

                    p{
                        margin: 0 5%;
                        font-size: .9em;
                    }
    
                    input{
                        border: 1px solid rgb(0 0 0 / 10%);
                    }
                }

                >p{
                    width: 100%;
                    text-align: center;
                    font-size: .9em;
                    color: red;
                    margin-top: 2%;
                }

                button{
                    width: 49%;

                    &:first-of-type{
                        margin-left: 0;
                    }

                    &:last-of-type{
                        margin-right: 0;
                    }
                }
            }
        }

        select{
            width: 15%;
            border: 0;
            box-shadow: none;
            background-color: transparent;
            border-radius: 0;
            margin-right: 1%;
            // font-weight: bold;

            // option{
            //     color: #949292;
            //     padding: 2% 0;

            //     &::selection{
            //         background-color: transparent;
            //     }

            //     &:checked{
            //         color: #494949;
            //         font-weight: 500;
            //     }
            // }
        }

        input{
            background-color: transparent;
            box-shadow: none;
            border: 0;
            padding: 1% 0 1% 2%;
            border-radius: 0;

            &:focus{
                background-color: transparent;
            }
        }
    }

    .container-filters.show-filters{
        height: auto;
        max-height: 500px;
        padding-bottom: 0.5%;
        display: flex;
        visibility: visible;
        overflow: hidden;
        transition: all 1.5s cubic-bezier(.25,.8,.25,1);
    }

    .tabela-dados{
        margin-top: 1%;
    
        .cabecalho-dados div{
            font-size: .85em;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            cursor: pointer;
            display: flex;
            align-items: center;

            >svg{
                margin-left: .5em;
            }

            &:hover{
                background-color: #e7e9ff;
            }
        }
    }

    .tabela-dados .corpo-dados .row-dados {
        cursor: pointer;

        div{
            font-size: .75em;
        
            svg{
                margin-right: 5%;
            }
        }
    }

    .selecao-contas-financeiro{
        width: 50%;
        height: 2.5em;
        margin-bottom: 2%;
        display: flex;
        justify-content: space-between;

        >div{
            height: 100%;
            width: 49.5%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background-color: #fff;

            &:first-of-type{
                border-bottom: 4px solid #f06363;

                &.item-ativo-contas{
                    background-color: #f06363;
                    color: #fff;
                }
            }

            &:last-of-type{
                border-bottom: 4px solid #70cc7d;

                &.item-ativo-contas{
                    background-color: #70cc7d;
                }
            }

            &:hover{
                background-color: #d8d8d8;
            }
        }
    }

    // .tabela-dados{
    //     margin: 0;
    // }
}

#campos-nova-conta, #campos-editar-conta{
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 70vh;
    margin-bottom: 2.5%;

    h5 button{
        aspect-ratio: 1;
        padding: .5% 1%;
    }
}

.modal-conta{
    h3{
        width: 100%;
    }
}

#campos-baixar-conta{
    display: flex;
    flex-wrap: wrap;
    margin-top: 2em;

    .info-modal{
        width: 100%;
    }
}