#main-financeiro{
    height: 100vh;

    .conteudo-financeiro{
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;

        .titulo-box{
            width: 100%;

            h3{
                font-size: 1.2em;
                font-weight: 500;
            }

            p{
                font-size: .9em;
                opacity: .8;

                &::first-letter{
                    text-transform: uppercase;
                }
            }
        }

        .divisao1{
            width: 60%;
            margin-right: 3%;
            display: flex;
            flex-direction: column;

            .mensagem-inicio-financeiro{
                width: 100%;
                display: flex;
                align-items: center;
    
                img{
                    width: 4.5em;
                    margin-right: 2%;
                    aspect-ratio: 1;
                    object-fit: cover;
                    border-radius: 50%;
                }

                >div{
                    display: flex;
                    flex-direction: column;
    
                    h4{
                        font-weight: 400;
                        font-size: 1.2em;
        
                        span{
                            font-weight: 500;
                        }
                    }

                    p{
                        font-size: .9em;
                        opacity: .8;
                    }
                }
            }

            .dados-contabilidade-financeiro{
                padding: 1em;
                margin-top: 4%;
                border-radius: 15px;
                background-color: #fff;
                display: flex;
                flex-wrap: wrap;

                .box-saldo{
                    width: 35%;
                    padding: 2%;
                    margin-top: 2%;
                    border: 1px solid rgb(0, 0, 0, .1);
                    border-radius: 15px;

                    h6{
                        font-weight: 400;
                        font-size: .8em;
                        opacity: .8;
                    }

                    h5{
                        margin-top: 2%;
                        font-size: 1.8em;
                    }
                }

                .box-movimentacao-saldo{
                    width: 62%;
                    height: auto;
                    margin: 2% 0 0 3%;
                    border: 1px solid rgb(0, 0, 0, .1);
                    border-radius: 15px;
                    display: flex;

                    >div{
                        width: 50%;
                        padding: 2%;
                        display: flex;
                        align-items: center;
                        justify-content: space-evenly;

                        svg{
                            font-size: 1.5em;
                        }

                        .valores-movimentacao{
                            display: flex;
                            flex-direction: column;

                            h6{
                                font-weight: 400;
                                font-size: .7em;
                                opacity: .8;
                            }
        
                            h5{
                                margin-top: 2%;
                                font-size: 1.3em;
                            }
                        }

                        &:first-of-type{
                            svg{
                                color: #208d20;
                            }
                        }

                        &:last-of-type{
                            border-left: .5px solid rgb(0, 0, 0, .1);

                            svg{
                                color: #d42f2f;
                            }
                        }
                    }
                }

                .box-grafico-saldo{
                    height: 14em;
                    width: 100%;
                    padding: 1%;
                    margin-top: 3%;
                    border-radius: 15px;
                    border: 1px solid rgb(0,0,0,0.1);
                    display: flex;

                    path{
                        &:focus{
                            outline: none;
                        }
                    }
                }
            }
        }

        .divisao2{
            width: 37%;
            height: 82%;
            display: flex;
            flex-direction: column;

            .contas-pagar-receber{
                padding: 1em;
                height: 56%;
                border-radius: 15px;
                background-color: #fff;
                display: flex;
                flex-wrap: wrap;

                .box-contas{
                    width: 100%;
                    height: 100%;

                    ul{
                        width: 100%;
                        margin-top: 4%;
                        padding-left: 3%;
                        overflow: auto;
                        height: 80%;

                        >div{
                            height: 100%;
                            display: flex;
                            align-items: center;
                        }

                        li{
                            width: 100%;
                            padding: 1%;
                            display: flex;
                            align-items: center;

                            time{
                                margin-right: 4%;
                                font-weight: 500;
                            }

                            div{
                                padding-left: 4%;

                                &.conta-pagar-item{
                                    border-left: 3px solid #f06363;
                                    // border-left: 3px solid #8884d8;
                                }

                                &.conta-receber-item{
                                    // border-left: 3px solid #8884d8;
                                    border-left: 3px solid #70cc7d;
                                }

                                h5{
                                    font-size: 1em;
                                    font-weight: 400;
                                }

                                h6{
                                    font-weight: 400;
                                    font-size: .75em;
                                    opacity: .8;
                                }
                            }
                        }
                    }
                }
            }

            .opcoes-financeiro{
                width: 100%;
                height: 40%;
                margin-bottom: 4%;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 1em;
                grid-row-gap: 1em;

                li{
                    width: 100%;
                    border-radius: 15px;
                    display: flex;
                    font-size: .9em;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    background-color: #fff;
                    cursor: pointer;

                    svg{
                        color: #5183f2;
                        font-size: 2em;
                        margin-bottom: 5%;
                    }
                }
            }
        }
    }
}