.conteudo-planos{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .tabela-planos{
        width: 49%;
        background-color: #fff;
        border-radius: 15px;
        display: flex;
        flex-direction: column;

        &:last-of-type{
            margin-left: 2%;
        }

        .titulo-tabela-plano{
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 15px 15px 0 0;
            padding: 1em;

            svg{
                margin-right: 2%;
                font-size: 1.1em;
            }

            h4{
                font-size: 1.2em;
            }

            &.titulo-plano-entradas{
                background-color: #70cc7d;
            }

            &.titulo-plano-saidas{
                background-color: #f06363;
                color: #fff;
            }
        }

        .corpo-planos{
            padding: 1em;

            .categoria-tabela-planos{
                margin-top: 2%;
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                &:first-of-type{
                    margin-top: 0;
                }

                .icon-plano{
                    width: 5%;
                }

                h4, h5{
                    width: 75%;
                    font-size: .95em;
                }

                .valor-plano{
                    width: 20%;
                    text-align: end;

                    &.valor-plano-categoria{
                        font-weight: 500;
                        font-size: 1em;
                    }

                    &.valor-plano-subcategoria{
                        font-size: .85em;
                        color: #43474a;
                    }
                }

                .subcategoria-tabela-planos{
                    width: 95%;
                    margin-left: 5%;
                    margin-top: .5em;
                    display: flex;

                    h5{
                        font-weight: 400;
                        color: #43474a;
                    }
                }
            }
        }
    }
}

#campos-novo-plano{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.5%;
}