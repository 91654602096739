.item-ativo{
    background-color: #fff !important;

    svg{
        color: #181818 !important;
    }
}

header{
    background-color: #f67600;
    width: 5%;
    height: 100%;
    padding: 0.5% 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: fixed;

    svg{
        font-size: 1.3em;
        color: #fff;
    }

    .options{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon-option{
            width: 100%;
            margin: 5% 0;
            display: flex;
            justify-content: center;

            a{
                border-radius: 15%;
                width: 80%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 20% 0;
                cursor: pointer;
                transition: .1s;

                &:hover{
                    background-color: #fff;

                    svg{
                        color: #181818 !important;
                    }
                }

                svg{
                    color: #fff;
                    transition: .1s;
                }
            }
        }

        .icon-configuracoes{
            width: 100% !important;
            margin: 0 !important;

            a{
                width: 100% !important;
                padding: 15% 0 !important;
                background-color: #6c757d;
                border-radius: 0;

                &:not(.item-ativo){
                    &:hover{
                        background-color: #3f4952;
    
                        svg{
                            color: #fff !important;
                        }
                    }
                }
            }
        }

        .icon-desconectar{
            width: 100%;
            background-color: #eb5050;
            padding: 15% 0;
            cursor: pointer;
            margin: 0 !important;

            &:hover{
                background-color: #d42f2f;
            }
        }
    }
}

@media print{
    header{
        display: none;
    }
}