#campos-novo-caixa, #campos-editar-caixa{
    display: flex;
    flex-wrap: wrap;
}

.corpo-caixas{
    .row-dados{
        cursor: pointer;

        &:hover{
            background-color: #d8d8d8;
        }
    }
}