.conteudo-itens-estoque{
    width: 100%;
    height: 100%;

    .lista-itens-estoque{
        width: 100%;
        height: 40%;
        margin-bottom: 4%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 1em 2em;

        >li{
            width: 100%;
            border-radius: 5px;
            box-shadow: 0 3px 6px rgba(0,0,0, .06);
            display: flex;
            flex-direction: column;
            padding: 1em;
            font-size: 0.9em;
            justify-content: flex-start;
            align-items: center;
            background-color: #fff;
            transition: .5s;
            cursor: pointer;

            &.item-add-itens{
                aspect-ratio: 0.769;
                padding: 2em;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border: 2px dashed #f67600;
                font-size: 1em;

                svg{
                    font-size: 2em;
                    margin-bottom: .5em;
                }
            }

            &:hover{
                box-shadow: 0 3px 6px rgba(0,0,0, .16);
            }

            >img{
                object-fit: cover;
                width: 100%;
                aspect-ratio: 1;
            }

            >.no-image-item-estoque{
                width: 100%;
                aspect-ratio: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid rgba(0,0,0, .2);
                padding: 1em;
                text-align: center;
            }
            
            .informacoes-itens-estoque{
                display: flex;
                flex-wrap: wrap;
                padding: 0 .5em;
                width: 100%;
                margin-top: 1em;

                h3{
                    width: 100%;
                    font-size: 1.4em;
                    font-weight: 400;
                    letter-spacing: .5px;
                    margin-bottom: 1em;
                }

                .dados-itens-estoque{
                    &:first-of-type{
                        width: 45%;
                    }

                    &:last-of-type{
                        width: 55%;
                    }

                    svg{
                        margin-right: .5em;
                    }
                }
            }
        }
    }
}

#campos-novo-item-estoque{
    input[type='file']{
        display: none;
    }

    .file-upload-label{
        margin: 1em auto 1em;
        display: flex;
        padding: 0.7em 3em;
        border-radius: 4px;
        cursor: pointer;
        background-color: #007bff;
        color: white;
        font-weight: 300;
        align-items: center;
        transition: 0.5s;

        &:hover{
            background-color: #0969d1;
        }

        svg{
            margin-right: .6em;
            font-size: 1.2em;
        }
    }
}