.conteudo-parceiros{
    width: 100%;
    height: 100%;
}

.corpo-parceiros{
    .row-dados{
        cursor: pointer;
    }
}

.selecao-parceiros-financeiro{
    width: 50%;
    height: 2.5em;
    margin-bottom: 2%;
    display: flex;
    justify-content: space-between;

    >div{
        height: 100%;
        width: 49.5%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: #fff;

        &:first-of-type{
            border-bottom: 4px solid #808080;

            &.item-ativo-parceiros{
                background-color: #808080;
                color: #fff;
            }
        }

        &:last-of-type{
            border-bottom: 4px solid #ff8c00;

            &.item-ativo-parceiros{
                background-color: #ff8c00;
                color: #fff;
            }
        }

        &:hover{
            background-color: #d8d8d8;
        }
    }
}

#campos-novo-parceiro{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.5%;
}