.main-item-estoque{
    height: 100vh !important;

    .conteudo-item-estoque{
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;

        h3{
            font-size: 1.3em;
            font-weight: 500;
            text-align: center;
            width: 100%;
        }
        
        .informacoes-item-estoque{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 22%;
            height: 89%;
            padding: 2em 0;
    
            img, .no-image-item-estoque{
                width: 50%;
                border-radius: 50%;
                aspect-ratio: 1;
                margin-bottom: 1em;
            }

            img{
                object-fit: cover;
            }

            .no-image-item-estoque{
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: .9em;
                padding: .5em;
                border: 1px solid rgb(0,0,0, 0.2);
            }
    
            .dados-item-estoque{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                width: 100%;

                h4{
                    font-weight: 500;
                    font-size: 1.2em;
                    margin-bottom: 2em;
                }

                .cards-item-estoque{
                    width: 100%;

                    li{
                        width: 100%;
                        background-color: #fff;
                        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                        border-radius: 10px;
                        padding: 1em;
                        display: flex;
                        align-items: center;
                        margin-bottom: 1em;

                        &:last-of-type{
                            margin-bottom: 0;
                        }

                        svg{
                            width: 25%;
                            font-size: 1.3em;
                        }

                        .info-card-item-estoque{
                            h5{
                                font-size: 1.2em;
                                margin-bottom: .2em;
                            }

                            h6{
                                font-weight: 400;
                                font-size: 0.8em;
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }
        }

        .estoque-sub-itens{
            width: 75%;
            height: 89%;
            margin-left: 3%;
            border-radius: 15px;
            background-color: #fff;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            padding: 2em;
            display: flex;
            flex-direction: column;

            h3{
                margin-bottom: 1em;
                width: 100%;
            }

            .conteudo-sub-itens-estoque{
                width: 100%;
                max-height: 30em;
                overflow-y: auto;
                padding-right: 1em;

                .btn-adicionar-peca{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    margin-top: 2em;

                    button{
                        width: 40%;
                        padding: 0.55em 1.8em;
                        cursor: pointer;
                        border-radius: 4px;
                        font-size: .9em;

                        svg{
                            margin-right: 1em;
                        }
                    }
                }

                .categoria-medida-estoque{
                    width: 100%;
                    padding: 0 0 1em;
                    margin-bottom: 1em;
                    border-bottom: 1px solid rgb(0,0,0, 0.4);
    
                    &:last-of-type{
                        margin-bottom: 0;
                        border-bottom: 0;
                    }
    
                    h4{
                        font-size: 1.4em;
                        font-weight: 500;
                        margin-bottom: 1em;
                        display: flex;
                        align-items: center;
                    }
    
                    ul{
                        display: grid !important;
                        grid-template-columns: repeat(4, 1fr);
                        gap: 1em 2em;
                        padding: 0 1em;
    
                        li{
                            width: 100%;
                            cursor: pointer;
                            border: 1px solid rgba(0, 0, 0, 0.2);
                            border-radius: 5px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            font-size: 1.1em;
    
                            img{
                                width: 100%;
                                aspect-ratio: 1;
                                object-fit: cover;
                                border-radius: 5px 5px 0 0;
                                border-bottom: 1px solid rgba(0,0,0, .2);
                            }
    
                            >.no-image-sub-item-estoque{
                                width: 100%;
                                aspect-ratio: 1;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                border-bottom: 1px solid rgba(0,0,0, .2);
                                border-radius: 5px 5px 0 0;
                                
                                svg{
                                    font-size: 1.8em;
                                }
                            }
    
                            .info-sub-item-estoque{
                                width: 100%;
                                display: flex;
                                flex-wrap: wrap;
    
                                .dados-sub-item-estoque{
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 50%;
                                    padding: .5em;
                                    font-size: .9em;

                                    &:nth-of-type(2){
                                        border-left: 1px solid rgba(0, 0, 0, 0.16);
                                    }

                                    &:last-of-type{
                                        width: 100%;
                                        border-top: 1px solid rgba(0, 0, 0, 0.16);
                                    }
    
                                    svg{
                                        margin-right: 10%;
                                    }
                                }
                            }
                        }
                    }
    
                    .content{
                        width: 100%;
                        margin: 0 auto;
                        max-height: 0;
                        font-size: .9em;
                        opacity: .8;
                        display: flex;
                        overflow: hidden;
                        visibility: hidden;
                        transition: all .5s cubic-bezier(.25,.8,.25,1);
                    }
        
                    .content.show{
                        // margin-top: .5em;
                        height: auto;
                        max-height: auto;
                        display: flex;
                        visibility: visible;
                        overflow: auto;
                        transition: all 1s cubic-bezier(.25,.8,.25,1);
                    }
                }
            }
        }

        .historico-sub-itens-estoque{
            width: 27%;
            height: 89%;
            margin-left: 3%;
            border-radius: 15px;
            background-color: #fff;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            padding: 2em;
        }
    }
}

#campos-novo-sub-item-estoque, #campos-edit-sub-item-estoque, #campos-baixar-peca-estoque{
    display: flex;
    flex-wrap: wrap;

    input[type='file']{
        display: none;
    }

    .file-upload-label{
        margin: 1em auto 1em;
        display: flex;
        padding: 0.7em 3em;
        border-radius: 4px;
        cursor: pointer;
        background-color: #007bff;
        color: white;
        font-weight: 300;
        align-items: center;
        transition: 0.5s;

        &:hover{
            background-color: #0969d1;
        }

        svg{
            margin-right: .6em;
            font-size: 1.2em;
        }
    }
}

#modal-peca{
    display: flex;
    flex-wrap: wrap;

    >img{
        width: 30%;
        aspect-ratio: 1;
        object-fit: cover;
    }

    >.no-image-sub-item-estoque{
        width: 30%;
        aspect-ratio: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 1px solid rgba(0,0,0, .2);
        border-radius: 0;
        
        svg{
            font-size: 1.8em;
        }
    }

    .info-modal{
        align-content: center;
        width: 65%;
        margin-left: 5%;
    }

    .informacoes-historico-peca{
        width: 100%;
        margin-top: 1em;
        border-top: 1px solid rgba(0, 0, 0, 0.4);

        ul{
            margin-top: 1em;
            padding: 0 .5em 0;
            width: 100%;
            max-height: 15em;
            overflow: auto;
    
            h3{
                font-weight: 500;
                margin-bottom: .5em;
            }
    
            li{
                line-height: 1.4;
                font-size: 0.9em;
                white-space: nowrap;
    
                time{
                    font-weight: 500;
                }
            }
        }
    }
}