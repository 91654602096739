.conteudo-estoque{
    width: 100%;
    height: 100%;

    .lista-categorias-estoque{
        width: 100%;
        height: 40%;
        margin-bottom: 4%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2em 2em;

        >li{
            width: 100%;
            min-height: 10.5em;
            border-radius: 5px;
            box-shadow: 0 3px 6px rgba(0,0,0, .06);
            display: flex;
            flex-wrap: wrap;
            font-size: 0.9em;
            justify-content: flex-start;
            background-color: #fff;
            transition: .5s;
            cursor: pointer;

            &.item-add-categoria{
                padding: 2em;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border: 2px dashed #f67600;
                font-size: 1em;

                svg{
                    font-size: 2em;
                    margin-bottom: .5em;
                }
            }

            &:hover{
                box-shadow: 0 3px 6px rgba(0,0,0, .16);
            }

            .conteudo-categoria-estoque{
                width: 100%;
                padding: 2em;
                display: flex;
                align-items: center;

                >svg{
                    font-size: 3em;
                    width: 10%;
                }
            
                .informacoes-categoria-estoque{
                    display: flex;
                    flex-wrap: wrap;
                    padding-left: 2em;
                    width: 90%;
    
                    h3{
                        width: 100%;
                        font-size: 1.4em;
                        font-weight: 400;
                        letter-spacing: .5px;
                        margin-bottom: 1em;
                    }
    
                    .dados-categoria-estoque{
                        width: 50%;
    
                        svg{
                            margin-right: .5em;
                        }
                    }
                }
            }

            .buttons-categoria-estoque{
                display: flex;
                width: 100%;

                button{
                    width: 50%;
                    padding: .6em 0;
                    cursor: pointer;

                    &:first-of-type{
                        border-radius: 0 0 0 5px;
                    }

                    &:last-of-type{
                        border-radius: 0 0 5px 0;
                    }
                }
            }
        }
    }
}