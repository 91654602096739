.conteudo-fluxo{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .filtro-fluxo{
        width: 26%;
        margin-right: 4%;
        background-color: #fff;
        position: sticky;
        top: 2em;
        border-radius: 15px;
        padding: 1em;

        h3{
            font-size: 1.2em;
            font-weight: 500;
            text-align: center;
            margin-bottom: 1.5em;
        }

        form{
            .conteudo-filtro-fluxo{
                display: flex;
                flex-wrap: wrap;
            }

            button{
                width: 100%;
                margin-top: 1em;
            }
        }
    }

    .tabela-fluxo{
        width: 70%;
        background-color: #fff;
        border-radius: 15px;
        padding: 1em;

        .column-zerado{
            height: 10em;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 0;
        }

        h4{
            font-size: 1.15em;
            margin-bottom: 1em;
        }

        .item-contas-fluxo{
            margin-top: 1.5em;

            &:first-of-type{
                margin-top: 0em;
            }

            .row-contas{
                display: flex;
                align-items: center;
                padding-left: 1.5em;
                margin-bottom: .5em;

                &:last-of-type{
                    margin-bottom: 0;
                }

                svg{
                    width: 10%;
                    font-size: 1.5em;
                }

                .descricao-contas-fluxo{
                    width: 75%;
                    display: flex;
                    flex-direction: column;
                    padding-left: 1.5em;

                    h6{
                        font-size: .9em;
                        font-weight: 500;
                    }

                    p{
                        font-size: .85em;
                        opacity: .8;
                    }
                }

                >p{
                    width: 15%;
                    display: flex;
                    justify-content: flex-end;
                    font-weight: 500;
                }
            }
        }
    }
}