// Animações

@keyframes rotate{
    from{ transform: rotate(-360deg); }
    to{ transform: rotate(360deg); }
}

@keyframes w {
    0% {
        background-position: 100% 50%;
    }
    
    100% {
        background-position: 0 50%;
    }
}

// Reset

button{
    border: 0;

    &:focus-visible{
        outline: none;
    }
}

input:not([type="checkbox"]), select, textarea{
    min-height: 2rem;
    border: 1px solid rgb(0 0 0 / 25%);
    box-shadow: 0px 0px 6px #00000012;
    padding: 0.4em 0.5em;
    font-size: 14px;
    border-radius: 4px;
    outline: none;
    resize: none;
    width: 100%;

    &[type=radio], [type=checkbox]{
        min-height: auto !important;
        width: auto !important;
    }

    &:focus{
        background: rgb(0 0 0 / 5%);
    }

    &:disabled{
        background-color: rgb(0 0 0 / 5%);
        opacity: 0.7;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.ql-toolbar.ql-snow .ql-formats {
    margin-right: 0 !important;
}

.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
    margin: 0 !important;
}

// Variáveis
$font-roboto: Roboto, sans-serif;
$font-montserrat: Montserrat, sans-serif;
$fundo-color: #efefef; // rgb(236, 236, 236)

// Mixins

@mixin widthCampo($width){
    width: $width;
    display: flex;
    flex-direction: column;
}

@mixin boxRounded($background){
    background-color: $background;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
}

@mixin colorBackground($background, $color){
    background-color: $background;
    color: $color;
}

// Scroll

::-webkit-scrollbar { /* Tamanho */
    width: 5px;
    height: 8px;
}

::-webkit-scrollbar-track { /* Fundo */
    background: #f1f1f1; 
}

::-webkit-scrollbar-thumb { /* Background */
    background: #888; 
}

::-webkit-scrollbar-thumb:hover { /* Hover */
    background: #555; 
}

*{
    box-sizing: border-box;
    font-family: 'Roboto';  
}

a, a:hover, a:visited, a:focus, a:active{
    text-decoration: none;
    color: inherit;
    outline: 0;
    cursor: auto;
}

ul, li{
    margin: 0;
    padding: 0;
    list-style: none;
}
      
p, h1, h2, h3, h4, h5, h6{
    margin: 0;
    padding: 0;
}

.button-red{
    @include colorBackground(#dc3545, #fff);
    transition: 0.5s;
    cursor: pointer;

    &:hover{
        background-color: #c22333;
    }
}

.button-darkred{
    @include colorBackground(#9e2323, #fff);
    transition: 0.5s;
    cursor: pointer;

    &:hover{
        background-color: #6e1d1d;
    }
}


.button-cyan{
    @include colorBackground(#4e5e70, #fff);
    transition: 0.5s;
    cursor: pointer;

    &:hover{
        background-color: #404c5c;
    }
}

.button-blue{
    @include colorBackground(#007bff, #fff);
    transition: 0.5s;
    cursor: pointer;

    &:hover{
        background-color: #0969d1;
    }
}

.button-green{
    @include colorBackground(#2f912f, #fff);
    transition: 0.5s;
    cursor: pointer;

    &:hover{
        background-color: #298129;
    }
};

.button-disabled{
    cursor: not-allowed !important;
    opacity: .8;
}

.placeholder-loading{
    animation: w 3s ease infinite;
    background: linear-gradient(-45deg,#f2f2f5 25%,#e5e5ea 37%,#f2f2f5 63%);
    background-size: 400% 100%!important;
}

.infinite-loader{
    display: flex;
    width: 100%;
    height: 8em;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2em 0 1em;
    justify-content: center;
    align-items: center;

    svg{
        font-size: 1.65em;
    }
}

.container-search{
    display: flex;
    align-items: center;
    margin-bottom: 2%;
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;

    select{
        width: 15%;
        border: 0;
        box-shadow: none;
        background-color: transparent;
        border-radius: 0;
        margin-right: 1%;
        // font-weight: bold;

        // option{
        //     color: #949292;
        //     padding: 2% 0;

        //     &::selection{
        //         background-color: transparent;
        //     }

        //     &:checked{
        //         color: #494949;
        //         font-weight: 500;
        //     }
        // }
    }

    input{
        background-color: transparent;
        box-shadow: none;
        border: 0;
        padding: 1% 0 1% 2%;
        border-radius: 0;

        &:focus{
            background-color: transparent;
        }

        &:not(:first-child){
            border-left: 1px solid rgb(0 0 0 / 10%);
        }
    }

    svg{
        margin: 0 2%;
        font-size: 1.2em;
        color: #a9a9a9;
    }
}

form{
    .campo-form{
        padding: 0 1.5%;
        margin-bottom: clamp(8px, 1.5%, 11px);
    
        label{
            font-size: 0.9em;
            margin: 0 0 0.1vw 0.5vw;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
    
            span{
                margin-left: 5%;
                font-size: .7em
            }
        }
    }

    h5{
        width: 100%;
        margin: 2% 0 1%;
        font-size: .9em;
    }

    .container-inputs{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        // span{
        //     margin-left: 10%;
        //     background-color: red;
        //     padding: 2%;
        // }
    }

    button{
        padding: 0.45em 1.6em;
        margin: 0 1%;
        white-space: nowrap;
        cursor: pointer;
        border-radius: 4px;
    }

    .buttons-form{
        margin-top: 1em;
        display: flex;
        justify-content: flex-end;
    }
}

.campo-5{ @include widthCampo(5%); }
.campo-10{ @include widthCampo(10%); }
.campo-15{ @include widthCampo(15%); }
.campo-20{ @include widthCampo(20%); }
.campo-25{ @include widthCampo(25%); }
.campo-30{ @include widthCampo(30%); }
.campo-35{ @include widthCampo(35%); }
.campo-40{ @include widthCampo(40%); }
.campo-45{ @include widthCampo(45%); }
.campo-50{ @include widthCampo(50%); }
.campo-55{ @include widthCampo(55%); }
.campo-60{ @include widthCampo(60%); }
.campo-65{ @include widthCampo(65%); }
.campo-70{ @include widthCampo(70%); }
.campo-75{ @include widthCampo(75%); }
.campo-80{ @include widthCampo(80%); }
.campo-85{ @include widthCampo(85%); }
.campo-90{ @include widthCampo(90%); }
.campo-95{ @include widthCampo(95%); }
.campo-100{ @include widthCampo(100%); }

// Tabela

.tabela-dados{
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    margin: 2% 0 0%;
    padding: 2% 1%;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    display: flex;
    flex-direction: column;

    .informacoes-dados{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5%;

        h3{
            font-size: 1.1em;
            font-weight: 400;
        }

        button{
            padding: .5% 2%;
            border-radius: 4px;
            cursor: pointer;
        }
    }

    .column-5{ width: 5%; }
    .column-10{ width: 10%; }
    .column-15{ width: 15%; }
    .column-20{ width: 20%; }
    .column-25{ width: 25%; }
    .column-30{ width: 30%; }
    .column-35{ width: 35%; }
    .column-40{ width: 40%; }
    .column-45{ width: 45%; }
    .column-50{ width: 50%; }
    .column-55{ width: 55%; }
    .column-60{ width: 60%; }
    .column-65{ width: 65%; }
    .column-70{ width: 70%; }
    .column-75{ width: 75%; }
    .column-80{ width: 80%; }
    .column-85{ width: 85%; }
    .column-90{ width: 90%; }
    .column-95{ width: 95%; }
    .column-100{ width: 100%; }

    .column-zerado{
        width: 100%;
        text-align: center;
        padding: 2% 0 0 !important;
        font-size: 0.9em !important;
        font-style: italic;
    }

    .column-icon{
        width: 2.5%;
        padding: 0 !important;
        font-size: 1em !important;
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            cursor: pointer;
        }
    }

    .column-icon-green{
        svg{
            &:hover{
                color: #2f912f;
            }
        }
    }

    .column-icon-red{
        svg{
            &:hover{
                color: #c22333;
            }
        }
    }

    .cabecalho-dados{
        width: 100%;
        display: flex;
        background-color: #f3f4fb;
        font-weight: 700;

        div{
            padding: 1% 0.5%;
            font-size: 0.9em;
        }
    }

    .corpo-dados{
        width: 100%;
        display: flex;
        flex-direction: column;
        font-weight: 400;

        .row-dados{
            width: 100%;
            display: flex;

            div{
                padding: 1% 0.5%;
                font-size: 0.8em;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                span{
                    margin-left: 1%;
                    font-weight: 500;
                }

                p{
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }

        h5{
            margin: 1% 1% 0 0;
            text-align: end;
        }
    }
}

body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: $fundo-color;
}

#root{
    width: 100%;
    height: 100%;
    position: relative;
}

main{
    height: 100%;
    width: 95%;
    margin-left: 5%;
    padding: 2%;
    display: flex;
    flex-wrap: wrap;

    .cabecalho-conteudo{
        display: flex;
        width: 100%;
        margin-bottom: 3%;
        align-items: center;
        justify-content: space-between;
    
        h2{
            font-weight: 500;
            font-size: 1.4em;
        }
    
        .buttons-conteudo{
            display: flex;
            align-items: center;
            justify-content: flex-end;
    
            button{
                padding: 0.55em 1.8em;
                margin: 0 0.3em;
                white-space: nowrap;
                cursor: pointer;
                border-radius: 4px;
            }
        }
    }
}

.box-content{
    width: 100%;
    padding: 2%;
    background-color: #fff;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
}

.no-info{
    width: 100%;
    text-align: center;
    // padding: 2% 0 0 !important;
    font-size: 0.9em !important;
    font-style: italic;
}

.is-owner{
    border-right: 5px solid #007bff !important;
}

.is-not-owner{
    border-left: 5px solid #007bff !important;
}

.column-zerado{
    width: 100%;
    text-align: center;
    padding: 2% 0 0 !important;
    font-size: 0.9em !important;
    font-style: italic;
}

.card-historico{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
    padding: 1.5% 2%;
    margin-bottom: 2%;
    align-items: center;
    border: 1px solid rgb(0,0,0,0.1);
    background-color: rgba(237, 237, 237, 0.3);
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    // box-shadow: 1px 3px 5px rgb(0 0 0 / 20%);
    transition: .3s;

    &:hover{
        // box-shadow: 1px 3px 5px rgb(0 0 0 / 30%);
        box-shadow: 0px 3px 6px rgb(0 0 0 / 30%);
    }

    &:last-of-type{
        margin-bottom: 0 !important;
    }

    .tipo-historico{
        width: 100%;
        margin-bottom: 1%;
        
        span{
            padding: 2px 15px;
            font-size: 0.8em;
            border-radius: 20px;
            color: #fff;
            user-select: none;
            background-color: #4e5e70;
        }
    }

    .foto-autor{
        width: 10%;

        img{
            width: 100%;
            aspect-ratio: 1/1;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .informacoes-historico{
        padding-left: 3%;
        width: 90%;

        .cabecalho-historico{
            font-size: 1.2em;
            margin: 0 1% 2% 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3{
                width: 95%;
                display: flex;
                align-items: center;

                span{
                    font-size: .5em;
                    background-color: red;
                    padding: 2px 10px;
                    color: #fff;
                    font-weight: 400;
                    margin-left: 1.5%;
                    border-radius: 4px;
                    user-select: none;
                }
            }

            .options-cabecalho-historico{
                display: flex;
                width: 5%;
                justify-content: flex-end;

                svg{
                    cursor: pointer;
                    font-size: 0.9em;
                    margin-left: 20%;

                    &:first-of-type{
                        margin-left: 0;
                    }
                }
            }
        }

        .dados-historico{
            display: flex;
            flex-wrap: wrap;

            h5{
                font-size: .9em;
                font-weight: 600;
            }

            p{
                font-size: .9em;
                font-weight: 400;
            }

            div:last-of-type{
                margin-top: 1%;
            }
        }
    }

    .buttons-historico{
        display: flex;
        justify-content: flex-end;
        margin-top: 2%;

        button{
            padding: 0.4em 1.2em;
            border-radius: 4px;
        }
    }
}

@media print{
    main{
        width: 100% !important;
        margin: 0;
    }
}